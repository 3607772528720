<template>
    <component :is="routerView" v-bind="$attrs" @write="write" @edit="edit" />
</template>

<script>
import BoardList from "./board-list.vue";
import BoardView from "./board-view.vue";
import BoardInput from "./board-input.vue";
export default {
    components: {
        BoardList,
        BoardView,
        BoardInput,
    },
    data(){
        return {
            mode: this.$route.params._board ? "view" : "list",
        }
    },
    methods: {
        write(){
            this.$router.push(`${this.$route.path}/create`);
        },
        edit(){
            this.$router.push(`${this.$route.path}/edit`);
        }
    },
    computed: {
        routerView(){
            if(this.$route.params._board){
                if(0 < this.$route.path.indexOf("edit")) return "board-input";
                return "board-view";
            }
            
            if(0 < this.$route.path.indexOf("create")) return "board-input";
            return "board-list";
        }
    },
}
</script>