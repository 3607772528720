var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.routerView, _vm._b({
    tag: "component",
    on: {
      "write": _vm.write,
      "edit": _vm.edit
    }
  }, 'component', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }