var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pagination-container"
  }, [_c('a', {
    staticClass: "pagination pagination--backward",
    on: {
      "click": function ($event) {
        return _vm.input(1);
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 14 12"
    },
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 14 12",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    staticClass: "st0",
    attrs: {
      "d": "M6.2,6.4C6,6.2,6,5.8,6.2,5.6l5.6-5.5c0.2-0.2,0.6-0.2,0.8,0s0.2,0.6,0,0.8l-5.1,5l5.3,5.2c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.6,0.2-0.8,0"
    }
  }), _c('path', {
    staticClass: "st0",
    attrs: {
      "d": "M0.2,6.4C0,6.2,0,5.8,0.2,5.6l5.6-5.5c0.2-0.2,0.6-0.2,0.8,0s0.2,0.6,0,0.8l-5.1,5l5.3,5.2c0.2,0.2,0.2,0.6,0,0.8C6.6,12,6.2,12,6,11.8"
    }
  })])]), _c('a', {
    staticClass: "pagination pagination--prev",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.page - 1);
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 8 12"
    },
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 8 12",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    staticClass: "st0",
    attrs: {
      "d": "M0.2,6.4C0,6.2,0,5.8,0.2,5.6l5.6-5.5c0.2-0.2,0.6-0.2,0.8,0s0.2,0.6,0,0.8l-5.1,5l5.3,5.2c0.2,0.2,0.2,0.6,0,0.8C6.6,12,6.2,12,6,11.8"
    }
  })])]), _vm._l([-2, -1, 0, 1, 2], function (i) {
    return _vm.visibility(_vm.page + i) ? _c('div', [i == 0 ? _c('a', {
      staticClass: "pagination pagination--current"
    }, [_vm._v(_vm._s(_vm.page))]) : _c('a', {
      staticClass: "pagination",
      on: {
        "click": function ($event) {
          return _vm.input(i + _vm.page);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.page + i))])]) : _vm._e();
  }), _c('a', {
    staticClass: "pagination pagination--next",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.page + 1);
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 8 12"
    },
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 8 12",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    staticClass: "st0",
    attrs: {
      "d": "M6.8,5.5C7,5.7,7,6.1,6.8,6.3l-5.6,5.5C1,12,0.6,12,0.4,11.8s-0.2-0.6,0-0.8l5.1-5L0.2,0.8C0,0.6,0,0.2,0.2,0C0.4-0.1,0.8-0.1,1,0.1"
    }
  })])]), _c('a', {
    staticClass: "pagination pagination--forward",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.count);
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 14 12"
    },
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 14 12",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    staticClass: "st0",
    attrs: {
      "d": "M2,11.8c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l5.3-5.2l-5.1-5c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l5.6,5.5c0.2,0.2,0.2,0.6,0,0.8"
    }
  }), _c('path', {
    staticClass: "st0",
    attrs: {
      "d": "M8,11.8c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l5.3-5.2l-5.1-5c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l5.6,5.5c0.2,0.2,0.2,0.6,0,0.8"
    }
  })])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }