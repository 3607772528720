var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready && _vm.authorized ? _c(_vm.SkinComponent, {
    tag: "component",
    attrs: {
      "code": _vm.code,
      "board": _vm.board
    }
  }) : _vm.ready && !_vm.authorized ? _c('v-container', [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "tile": "",
      "outlined": "",
      "height": "300",
      "elevation": "0"
    }
  }, [_c('v-layout', {
    staticClass: "ma-auto",
    attrs: {
      "justify-center": "",
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('div', [_c('v-card-title', {
    staticClass: "justify-center",
    attrs: {
      "for": "qna-validate"
    }
  }, [_c('label', {
    staticClass: "text-center",
    attrs: {
      "for": "qna-validate"
    }
  }, [_vm._v("게시글 열람을 위해 "), _c('br', {
    staticClass: "b-lg-none"
  }), _vm._v("비밀번호를 입력해주세요.")])]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-text-field', {
    staticClass: "shrink",
    attrs: {
      "maxlength": "6",
      "type": "password",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.password,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "password", $$v);
      },
      expression: "filter.password"
    }
  }), _c('v-btn', {
    staticClass: "ml-1 button button--primary",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.search
    }
  }, [_vm._v("확인")])], 1)], 1)], 1)])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }