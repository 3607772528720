<template>
    <component v-if="ready" :is="lazyComponent" 
        v-bind="$attrs"
        :notices="notices" 
        :summary="summary" 
        :boards="boards" 
        :filter="filter" 
        :page="page" 
        :limit="limit"
        v-on="$listeners"
        @search="search" 
        :hideWriteButton="hideWriteButton"
        @onPage="onPage">

        <template #write-button>
            <v-btn v-if="scope.find(scope => writeScope.includes(scope))" x-large color="primary" @click="write">글쓰기</v-btn>
        </template>
        <template #pagination>
            <pagination-component :value="page" :count="Math.ceil(summary.totalCount / limit)" @input="value => onPage(value)"></pagination-component>
        </template>
    </component>
</template>

<script>
import api from "@/api";

import PaginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    components: {
        PaginationComponent
    },
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
        category: {}, 

        writeScope: { type: Array, default(){ return [] } },
        hideWriteButton: { type: Boolean, default: false },

        to: String,
    },
    data() {
        return {
            ready: false,

            filter: {
                code: this.$props.code,
                category: this.$props.category,
                searchKey: "",
                searchValue: "",
            },

            page: +this.$route.query.page || +this.$attrs.page || 1,
            skip: this.$attrs.skip || ((this.$route.query.page || this.$attrs.page || 1) - 1) * this.$attrs.limit,
            limit: +this.$attrs.limit || 10,

            summary: { totalCount: 0 },
            notices: [],
            boards: [],
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search(false);

            this.ready = true;
        },

        async search(routable = true, loadmore = false) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, { page: this.page }),
                    });
                }
                var { summary, boards } = await api.v1.boards.gets({
                    headers: {
                        code: this.code,
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter,
                });

                this.summary = summary;

                this.boards = loadmore ? [...this.boards, ...boards] : boards;
            } catch (error) {
                this.handleError(error);
            }
        },

        onLoadmore() {
            this.skip = this.boards.length;
            this.search(true, true);
        },

        onPage(page, routable = true) {
            this.page = page;
            this.skip = (page - 1) * this.limit;
            this.search(routable);
        },

        handleError(error) {
            console.error(error);
            alert(error.response ? error.response.data.message : error.message);
        },
    },
    computed: {
        scope(){
            return this.$store.state.payload?.scope || [];
        },
        lazyComponent() {
            return () => import(`./skin/${this.$props.skin}/list.vue`);
        },
    },
    watch: {
        "$route.query.page"() {
            this.onPage(+this.$route.query.page || 1, false);
        },
    },
};
</script>
